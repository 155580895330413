import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UploadApplication } from '@xing-com/crate-common-graphql-types';
import { useFetch } from '@xing-com/crate-xinglet';

import { UploadRequestImageUploadDocument } from '../../graphql/mutations/upload-request-image-upload.gql-types';
import type { FileUploaded } from '../../types/upload-files';

type UploadFileResponse = {
  error?: string;
  data?: FileUploaded;
};

export const useUploadFile = () => {
  const [uploadRequest] = useMutation(UploadRequestImageUploadDocument);
  const fetch = useFetch();

  const uploadImage = useCallback(
    async (file: File): Promise<UploadFileResponse> => {
      try {
        const { data } = await uploadRequest({
          variables: {
            application: UploadApplication.EntityPages,
            fileSize: file.size,
            fileName: file.name || 'image',
            type: file.type || 'image/jpeg',
          },
        });

        if (!data?.uploadRequest?.success) {
          return {
            error: 'UPLOAD_SERVICE_REQUEST_ERROR',
          };
        }

        const { id, url, authToken } = data.uploadRequest.success;

        if (!url || !authToken) {
          return {
            error: 'UPLOAD_SERVICE_REQUEST_ERROR',
          };
        }

        const response = await fetch(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Upload-Offset': '0',
            'Tus-Resumable': '1.0.0',
            'Content-Type': 'application/offset+octet-stream',
          },
          body: file,
        });

        if (!response.ok) {
          return {
            error: 'UPLOAD_FAILED',
          };
        }

        return { data: { file: file, id } };
      } catch {
        return {
          error: 'UPLOAD_FAILED',
        };
      }
    },
    [uploadRequest, fetch]
  );

  const uploadFile = uploadImage;

  return { uploadImage, uploadFile };
};
